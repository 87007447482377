import React from "react";
import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import translate from "../../../i18n/translate";
import { useHistory, useLocation } from "react-router-dom";
import SubNav from "./SubNav";
import {
  getJawwal3g,
  getNotRnewJawwal3g,
  getRnewJawwal3g,
} from "../../../actions/companiesAction";
import { useIntl } from "react-intl";
import Selected from "./Selected";
import JawalMinCard from "./JawalMinCard";
import logo from "../../../assests/images/bundles/jawwal/banner.jpg";
import CompaniesNav from "../../common/CompaniesNav";
import CompaniesSkeleton from "../../homePage/CompaniesSkeleton";
import { jsonParser } from "shared/utils/ppUtils";

const Jawwal3g = ({
  getJawwal3g,
  jawwal3g,
  Jawwal3gLoading,
  jawal3gNotReniew,
  jawal3gReniew,
  isDarktheme,
}) => {
  const history = useHistory().location.pathname;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [isRenew, setIsRenew] = useState(false);
  const [isNotRenew, setIsNotRenew] = useState(false);

  const [selected, setSelected] = useState("");
  const [jawwalMin, setJawwalMin] = useState("");
  const [jawwalRom, setJawwalRom] = useState("");
  const [credit, setCredit] = useState("");
  const [columnStyle, setColumnStyle] = useState("col-lg-3 col-md-4 col-sm-4");
  const collapseRef = useRef([]);
  const listRefNotRen = useRef(null);
  const listRefRen = useRef(null);
  const [plus, setPlus] = useState("");

  let mobileNo =
    history.split("/")[3].slice(0, 3) +
    "-" +
    history.split("/")[3].slice(3, 6) +
    "-" +
    history.split("/")[3].slice(6, 10);
  let routerMobileNo = history.split("/")[3];

  const intl = useIntl();
  useEffect(() => {
    const number = params.get("number");
    const mobileNumber = number || mobileNo;
    getJawwal3g(mobileNumber, false);

    const handleSessionStorageChange = () => {
      document.title = intl.formatMessage({ id: "3G Jawwal | Phone Play" });

      if (sessionStorage.Jawwal3g) {
        setSelected(jsonParser(sessionStorage.Jawwal3g));
      }
      if (sessionStorage.JawwalMin) {
        setJawwalMin(jsonParser(sessionStorage.JawwalMin));
      }
      if (sessionStorage.JawwalCredit) {
        setCredit(jsonParser(sessionStorage.JawwalCredit));
      }
      if (sessionStorage.JawwalRom) {
        setJawwalRom(jsonParser(sessionStorage.JawwalRom));
      }
      if (sessionStorage.JawwalPlus) {
        setPlus(jsonParser(sessionStorage.JawwalPlus));
      }
      refreshColumnStyle();
    };

    handleSessionStorageChange();

    document.addEventListener("click", handleClickOutside);
    window.addEventListener(
      "sessionStorageChanged",
      handleSessionStorageChange
    );

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener(
        "sessionStorageChanged",
        handleSessionStorageChange
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileNo, history]);

  const handleClickOutside = (event) => {
    if (
      listRefNotRen.current &&
      !listRefNotRen.current.contains(event.target) &&
      listRefRen.current &&
      !listRefRen.current.contains(event.target)
    ) {
      collapseRef.current.forEach((item) => {
        item?.classList?.remove("show");
      });
    }
  };

  const onTypeClick = (item) => {
    if (item.active !== "1" && item.active?.toLowerCase() !== "true") {
      return;
    }
    sessionStorage.Jawwal3g = JSON.stringify(item);
    document
      .getElementsByTagName("html")[0]
      .scrollIntoView({ behavior: "smooth" });

    setSelected(item);
  };
  const onRenewClick = () => {
    setIsRenew(!isRenew);
    setIsNotRenew(false);
  };
  const onNotRenewClick = () => {
    setIsNotRenew(!isNotRenew);
    setIsRenew(false);
  };

  const refreshClick = () => {
    getJawwal3g(mobileNo, true);
  };

  const refreshColumnStyle = () => {
    switch (localStorage.size) {
      case "default":
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6");
        break;
      case "column3":
        setColumnStyle("col-lg-4 col-md-6 col-sm-6 col-6 card-lg");
        break;
      case "column4":
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6  card-md");
        break;
      case "column6":
        setColumnStyle("col-lg-2 col-md-2 col-sm-4 col-6 card-sm");
        break;
      default:
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6");
    }
  };
  let topDiv = null;

  return (
    <div ref={(ref) => (topDiv = ref)} className="mb-5">
      <div>
        <CompaniesNav
          providerId={window?.history?.state?.state?.provider_Id}
          title="Jawwal"
          logo={logo}
          mobile={routerMobileNo}
          onRefresh={refreshClick}
          actionWidth="300px"
          backLink="/company/jawwalNo"
        >
          <button
            type="button"
            className={`btn btn-light btn-md btn-renewable main-text border-info border-left px-1 ${
              isRenew ? "selected-btn" : ""
            }`}
            onClick={onRenewClick}
          >
            {translate("Renewable")}
          </button>
          <button
            type="button"
            className={`btn btn-light btn-md btn-renewable main-text border-info border-left px-1 ${
              isNotRenew ? "selected-btn" : ""
            }`}
            onClick={onNotRenewClick}
          >
            {translate("notRenew")}
          </button>
        </CompaniesNav>

        <div className="row">
          <div className="col-12">
            <SubNav mobile={history.split("/")[3]} />
          </div>
        </div>
      </div>
      <div className="position-relative">
        <Selected
          min={jawwalMin}
          setMin={setJawwalMin}
          g3={selected}
          setg3={setSelected}
          credit={credit}
          setCredit={setCredit}
          rom={jawwalRom}
          setRom={setJawwalRom}
          setPlus={setPlus}
          plus={plus}
        />

        <hr
          className="mt-3"
          style={{
            border: "2px solid #42ace3",
            backgroundColor: "#42ace3",
            fontWeight: "bolder",
          }}
        />
        {(isRenew || !isNotRenew) && (
          <div
            className={`${
              isDarktheme ? "dark-card" : "card"
            } list-cards p-3 mt-4`}
            ref={listRefNotRen}
          >
            <div className="row">
              <h3 className="mb-4">{translate("Renewable")}</h3>
              {Jawwal3gLoading &&
                [...Array(6)].map((e, i) => (
                  <CompaniesSkeleton
                    height={130}
                    columnStyle={columnStyle}
                    key={i}
                  />
                ))}
              {jawal3gReniew.length === 0 && !Jawwal3gLoading ? (
                <div className="d-flex justify-content-center mt-3">
                  <h1 className="warning-text">{translate("NoAvailable")}</h1>
                </div>
              ) : (
                jawal3gReniew.map((item, index) => (
                  <JawalMinCard
                    isDarktheme={isDarktheme}
                    collapseRef={collapseRef}
                    key={index.toString()}
                    item={item}
                    onTypeClick={onTypeClick}
                    columnStyle={columnStyle}
                    index={index}
                  />
                ))
              )}
            </div>
          </div>
        )}

        {(isNotRenew || !isRenew) && (
          <div
            className={`${
              isDarktheme ? "dark-card" : "card"
            } list-cards p-3 mt-4`}
            ref={listRefRen}
          >
            <div className="row">
              <h3 className="mb-4">{translate("notRenew")}</h3>
              {Jawwal3gLoading &&
                [...Array(6)].map((e, i) => (
                  <CompaniesSkeleton
                    height={130}
                    columnStyle={columnStyle}
                    key={i}
                  />
                ))}
              {jawal3gNotReniew.length === 0 && !Jawwal3gLoading ? (
                <div className="d-flex justify-content-center mt-3">
                  <h1 className="warning-text">{translate("NoAvailable")}</h1>
                </div>
              ) : (
                jawal3gNotReniew.map((item, index) => (
                  <JawalMinCard
                    isDarktheme={isDarktheme}
                    collapseRef={collapseRef}
                    key={index.toString()}
                    item={item}
                    onTypeClick={onTypeClick}
                    columnStyle={columnStyle}
                    index={index + jawal3gReniew?.length}
                  />
                ))
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  auth,
  companies: { Jawwal3gLoading, jawwal3g, jawal3gReniew, jawal3gNotReniew },
  darkTheme: { isDarktheme },
}) => ({
  auth,
  jawwal3g,
  jawal3gReniew,
  jawal3gNotReniew,
  Jawwal3gLoading,
  isDarktheme,
});
export default connect(mapStateToProps, {
  getJawwal3g,
  getRnewJawwal3g,
  getNotRnewJawwal3g,
})(Jawwal3g);
