import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import React, { useContext, useEffect, useState } from "react";
import translate from "../../../i18n/translate";
import { chargeOoredoo } from "../../../actions/companiesAction";
import Badge from "../../ui/Badge/Badge";
import { isEmpty, isNil } from "lodash";
import Button from "@mui/material/Button";
import { IS_MOBILE_DISPLAY } from "shared/constants/constants";
import { noBalanceContext } from "reducers/no_balance";
import BoltIcon from "@mui/icons-material/Bolt";
import { useDispatch, useSelector } from "react-redux";
import { setNumberChanged } from "../../../actions/companiesAction";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import Spinner from "components/ui/spinner/Spinner";
import { intl } from "i18n/provider";
import { useIsRtl } from "shared/hooks/ppHooks";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import { ShowToastPromise } from "components/common/ShowToastPromise";
import ConfirmModal from "../shared/InfoModal";

const Selected = ({
  min,
  setMin,
  g3,
  setg3,
  credit,
  setCredit,
  shabab,
  setShabab,
  setRom,
  rom,
  chargeOoredoo,
  isDarkTheme,
}) => {
  const noBalanceCtx = useContext(noBalanceContext);
  const history = useHistory().location.pathname;
  const pathname = history.split("/");
  const pushHistory = useHistory();
  const isRtL = useIsRtl();
  const [loadingSpinner, isLoading] = useState(false);
  const [openInfoModel, setOpenInfoModel] = useState(false);
  const [chargeType, setChargeType] = useState("");
  const [quickProcessLoading, setQuickProcessLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const numberFromQuery = queryParams.get("number") || "";
  const mobileNumber = pathname?.[pathname.length - 1];
  const selectedCards = [credit, min, g3, rom, shabab];
  const dispatch = useDispatch();
  // To read the state value
  const numberIsChanged = useSelector(
    (state) => state.companies.numberIsChanged
  );
  useEffect(() => {
    if (numberIsChanged) {
      dispatch(setNumberChanged(false));
      !credit && clearSelected();
    }
  }, [numberIsChanged]);

  const closeToast = (toastId) => {
    toast.dismiss(toastId); // Dismiss the specific toast
  };

  const onCreditRemove = () => {
    sessionStorage.removeItem("ooredooCredit");
    setCredit("");
  };
  const remove3g = () => {
    sessionStorage.removeItem("ooredoo3g");
    setg3("");
  };
  const removeMin = () => {
    sessionStorage.removeItem("ooredooMin");
    setMin("");
  };
  const removeRom = () => {
    sessionStorage.removeItem("ooredooRom");
    setRom("");
  };
  const removeShabab = () => {
    sessionStorage.removeItem("ooredooSuper");
    setShabab("");
  };

  const charge = async (type) => {
    const isQuickly = type === "quickly";

    const check = await chargeOoredoo(
      {
        ooredoo3g: g3 || null,
        ooredooRom: rom || null,
        ooredooCredit: credit || null,
        ooredooMin: min || null,
        ooredooSuper: shabab || null,
      },
      history,
      pushHistory,
      !isQuickly
    );
    if (check === "noBalance") {
      noBalanceCtx.openCardHandler();
      if (isQuickly) {
        throw new Error("quickly");
      }
    }
  };
  const onClickTypeCredit = async (e, type = "normal") => {
    e.preventDefault();
    queryParams.delete("number");

    if (type !== "quickly") {
      isLoading(true);

      try {
        await charge(type);
      } finally {
        clearSelected();
        pushHistory.push(`/`);
        isLoading(false);
      }
    } else {
      setQuickProcessLoading(true);
      queryParams.delete("number");
      ShowToastPromise(async () => await charge(type), {
        mobileNumber,
      });

      if (pathname.length > 1) {
        pathname.pop();
      }

      setQuickProcessLoading(false);
      clearSelected();
      const newPath = pathname.join("/");
      pushHistory.push(`${newPath}/undefined`);
    }
  };
  const clearSelected = () => {
    onCreditRemove();
    remove3g();
    removeMin();
    removeRom();
    removeShabab();
  };
  const disabledButton =
    !/^\d{10}$/.test(mobileNumber) ||
    (numberFromQuery && mobileNumber !== numberFromQuery) ||
    loadingSpinner ||
    (credit?.price ? parseFloat(credit?.price) : 0) +
      (rom?.price ? parseFloat(rom?.price) : 0) +
      (g3?.price ? parseFloat(g3?.price) : 0) +
      (shabab?.price ? parseFloat(shabab?.price) : 0) +
      (min?.price ? parseFloat(min?.price) : 0) ===
      0;

  const totalAmount =
    (credit?.price ? parseFloat(credit?.price) : 0) +
    (rom?.price ? parseFloat(rom?.price) : 0) +
    (g3?.price ? parseFloat(g3?.price) : 0) +
    (shabab?.price ? parseFloat(shabab?.price) : 0) +
    (min?.price ? parseFloat(min?.price) : 0);

  return (
    <div className="row">
      {loadingSpinner && <Spinner />}

      <div
        className={IS_MOBILE_DISPLAY ? "col-12" : "col-9"}
        style={{
          margin: "0 auto",
        }}
      >
        <div
          className={`card m-4s fixed-top1 position-sticky mt-2 ${
            isDarkTheme && "dark-background"
          }`}
          style={{
            padding: "10px 0 30px",
            minHeight: IS_MOBILE_DISPLAY ? "auto" : 170,
          }}
        >
          {IS_MOBILE_DISPLAY && (
            <div className="mt-2">
              <div
                className={` ${isDarkTheme && "dark-background"}`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0 10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "60%",
                  }}
                >
                  <h5
                    className="text-muted mt-1 mb-2"
                    title="Balance"
                    style={{ fontSize: "1.2rem" }}
                  >
                    {translate("total")}:{" "}
                  </h5>
                  <h3 className="text-info">
                    ₪{" "}
                    {(credit?.price ? parseFloat(credit?.price) : 0) +
                      (rom?.price ? parseFloat(rom?.price) : 0) +
                      (g3?.price ? parseFloat(g3?.price) : 0) +
                      (shabab?.price ? parseFloat(shabab?.price) : 0) +
                      (min?.price ? parseFloat(min?.price) : 0)}
                  </h3>
                </div>
                <div>
                  <Button
                    type="submit"
                    disabled={disabledButton}
                    onClick={(e) => {
                      setOpenInfoModel(true);
                      setChargeType("normal");
                    }}
                    // title="accept"
                    variant="contained"
                    color="success"
                    startIcon={
                      <HourglassTopIcon sx={isRtL ? { mr: 1, ml: 1 } : {}} />
                    }
                    fullWidth
                    sx={{ marginBottom: 0.5 }}
                  >
                    {translate("accept")}
                  </Button>
                  <Button
                    type="submit"
                    disabled={disabledButton}
                    onClick={(e) => {
                      setChargeType("quickly");
                      setOpenInfoModel(true);
                    }}
                    variant="contained"
                    color="warning"
                    startIcon={
                      quickProcessLoading ? (
                        <CircularProgress size={24} sx={{ color: "white" }} />
                      ) : (
                        <BoltIcon sx={isRtL ? { mr: 1, ml: 1 } : {}} />
                      )
                    }
                    fullWidth
                  >
                    {quickProcessLoading ? null : translate("quickly")}
                  </Button>
                </div>
              </div>
            </div>
          )}
          <div className="row mt-1 fixed-topx px-3">
            {!isNil(credit) && !isEmpty(credit) && (
              <div className="col-lg-3 col-md-4 col-sm-4 mt-3">
                <div className="card">
                  <div className="frame1">
                    <img
                      alt="Ooredoo Credit"
                      src={
                        credit.url ||
                        "https://res.cloudinary.com/dtu4lltbk/image/upload/v1622203339/eced7efa-a16b-4fdd-9528-2c1f10356e1c_lzfhei.jpg"
                      }
                      width="260px"
                      height="100px"
                    ></img>
                    {credit.flexiblePrice && (
                      <label className="text-abs-oore">{credit.price}</label>
                    )}
                    <a href className="close-btn" onClick={onCreditRemove}>
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            )}
            {!isNil(min) && !isEmpty(min) && (
              <div className="col-lg-3 col-md-4 col-sm-4 mt-3">
                <div className="card">
                  <div className="frame1">
                    <img
                      alt="Ooredoo Min"
                      src={min.url}
                      width="260px"
                      height="100px"
                    ></img>
                    {/* <MinOoredooCard item={min} choose={true} /> */}
                    {(min.auto_renew === "True" ||
                      min.auto_renew === "true") && (
                      <Badge text={translate("Renewable")}></Badge>
                    )}
                    <a href className="close-btn" onClick={removeMin}>
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            )}
            {!isNil(g3) && !isEmpty(g3) && (
              <div className="col-lg-3 col-md-4 col-sm-4 mt-3">
                <div className="card">
                  <div className="frame1">
                    <img
                      alt="Ooredoo 3G"
                      src={g3.url}
                      width="260px"
                      height="100px"
                    ></img>
                    {/* <Ooredoo3gCard item={g3} choose={true} /> */}
                    {(g3.auto_renew === "True" || g3.auto_renew === "true") && (
                      <Badge text={translate("Renewable")}></Badge>
                    )}
                    <a href className="close-btn">
                      <i
                        className="fa fa-times"
                        aria-hidden="true"
                        onClick={remove3g}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            )}
            {!isNil(rom) && !isEmpty(rom) && (
              <div className="col-lg-3 col-md-4 col-sm-4 mt-3">
                <div className="card">
                  <div className="frame1">
                    <img
                      alt="Ooredoo Rom"
                      src={rom.url}
                      width="260px"
                      height="100px"
                    ></img>
                    {(rom.auto_renew === "True" ||
                      rom.auto_renew === "true") && (
                      <Badge text={translate("Renewable")}></Badge>
                    )}
                    <a href className="close-btn" onClick={removeRom}>
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            )}
            {!isNil(shabab) && !isEmpty(shabab) && (
              <div className="col-lg-3 col-md-4 col-sm-4 mt-3">
                <div className="card">
                  <div className="frame1">
                    <img
                      alt="Ooredoo Shabab"
                      src={shabab.url}
                      width="260px"
                      height="100px"
                    ></img>
                    {(shabab.auto_renew === "True" ||
                      shabab.auto_renew === "true") && (
                      <Badge text={translate("Renewable")}></Badge>
                    )}
                    <a href className="close-btn" onClick={removeShabab}>
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!IS_MOBILE_DISPLAY && (
        <div className="col-3">
          <div className="card total-balance-card mt-2">
            <div
              className={`card-body p-2 ${isDarkTheme && "dark-background"}`}
            >
              <h5
                className="text-muted mt-1 mb-2"
                title="Balance"
                style={{ fontSize: "1.2rem" }}
              >
                {translate("total")}
              </h5>
              <h3 className="text-info mt-2">
                ₪{" "}
                {(credit?.price ? parseFloat(credit?.price) : 0) +
                  (rom?.price ? parseFloat(rom?.price) : 0) +
                  (g3?.price ? parseFloat(g3?.price) : 0) +
                  (shabab?.price ? parseFloat(shabab?.price) : 0) +
                  (min?.price ? parseFloat(min?.price) : 0)}
              </h3>
              <Button
                type="submit"
                disabled={disabledButton}
                onClick={(e) => {
                  setOpenInfoModel(true);
                  setChargeType("normal");
                }}
                // title="accept"
                variant="contained"
                color="success"
                startIcon={
                  <HourglassTopIcon sx={isRtL ? { mr: 1, ml: 1 } : {}} />
                }
                fullWidth
                sx={{ marginBottom: 0.5 }}
              >
                {translate("accept")}
              </Button>
              <Button
                type="submit"
                disabled={disabledButton}
                onClick={(e) => {
                  setChargeType("quickly");
                  setOpenInfoModel(true);
                }}
                variant="contained"
                color="warning"
                startIcon={
                  quickProcessLoading ? (
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  ) : (
                    <BoltIcon sx={isRtL ? { mr: 1, ml: 1 } : {}} />
                  )
                }
                fullWidth
              >
                {quickProcessLoading ? null : translate("quickly")}
              </Button>
            </div>
          </div>
        </div>
      )}
      <ConfirmModal
        phoneNumber={mobileNumber}
        selectedCards={selectedCards}
        open={openInfoModel}
        handleConfirm={(e) => {
          setOpenInfoModel(false);
          onClickTypeCredit(e, chargeType);
        }}
        handleClose={() => setOpenInfoModel(false)}
        totalAmount={totalAmount}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isDarkTheme: state.darkTheme.isDarktheme,
});

export default connect(mapStateToProps, { chargeOoredoo })(Selected);
